import { onMounted, onUnmounted, type Ref } from 'vue';

export function useClickOutside(
  ref: Ref<HTMLElement | null>[],
  handler: () => void,
) {
  const listener = (event: MouseEvent) => {
    if (!ref.some(el => el?.value?.contains(event.target as Node))) {
      handler();
    }
  };

  onMounted(() => {
    document.addEventListener('click', listener);
  });

  onUnmounted(() => {
    document.removeEventListener('click', listener);
  });
}
